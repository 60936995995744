const swiper = new Swiper('.slider__year', {
  slidesPerView: 'auto',
  spaceBetween: 0,
  direction: "vertical",
  allowTouchMove: 'true',
  initialSlide: 3,
  navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
  },
  on: {
      init() {
          updateClasses(this);
      },
      slideChange() {
          updateClasses(this);
      },
  },
})
function updateClasses({ $el, slides, activeIndex }) {
  $el.find('.swiper-slide-prev-2').removeClass('swiper-slide-prev-2');
  slides.eq(activeIndex).prev().prev().addClass('swiper-slide-prev-2');

  $el.find('.swiper-slide-next-2').removeClass('swiper-slide-next-2');
  slides.eq(activeIndex).next().next().addClass('swiper-slide-next-2');

  $el.find('.swiper-slide-prev-3').removeClass('swiper-slide-prev-3');
  slides.eq(activeIndex).prev().prev().prev().addClass('swiper-slide-prev-3');

  $el.find('.swiper-slide-next-3').removeClass('swiper-slide-next-3');
  slides.eq(activeIndex).next().next().next().addClass('swiper-slide-next-3');

  $el.find('.swiper-slide-next-4').removeClass('swiper-slide-next-4');
  slides.eq(activeIndex).next().next().next().next().addClass('swiper-slide-next-4');

  $el.find('.swiper-slide-next-5').removeClass('swiper-slide-next-5');
  slides.eq(activeIndex).next().next().next().next().next().addClass('swiper-slide-next-5');

  $el.find('.swiper-slide-next-6').removeClass('swiper-slide-next-6');
  slides.eq(activeIndex).next().next().next().next().next().next().addClass('swiper-slide-next-6');

  $el.find('.swiper-slide-next-7').removeClass('swiper-slide-next-7');
  slides.eq(activeIndex).next().next().next().next().next().next().next().addClass('swiper-slide-next-7');
}
$('a[href^="#"').on('click', function() {

  let href = $(this).attr('href');

  $('html, body').animate({
      scrollTop: $(href).offset().top
  });
  return false;
});